import Vue from 'vue';
import { LOGIN, FORGOT_PASSWORD } from '@/store/user/actions.type';
import { SET_CURRENT_USER, SET_USER_DATA, FORGOT_INFO, RESET_INFO } from '@/store/user/mutations.type';
import { setAuthToken } from '@/services/auth/setAuthToken';
import store from '@/store';
import router from '@/router/index';
import { SET_CURRENT_CLIENT } from '@/store/client/mutations.type';
import { ENTER_CLIENT } from '@/store/client/actions.type';
import { AxiosResponse } from 'axios';

export const forgotPasword = async (context: any, email: any) => {
  const { data } = await Vue.axios.post('/api/forgot_password', {email})
  context.commit(FORGOT_INFO, data);
  return data;
};
export const resetPassword = async (context: any, userPasswords: any) => {
  const { data } = await Vue.axios.post(`/api/reset_password/${userPasswords.token}`, userPasswords)
  context.commit(RESET_INFO, data);
  return data
};

export const resetPasswordHard = (context: any, data: { user_id: string, pwd: string }) => {
  return Vue.axios.post(`/api/reset_password_hard`, data)
  // .then((response) => {
  //   context.commit(RESET_INFO, response.data);
  // });
};

function decodeToken(response: any) {
  const base64Url = response.split('.')[1];
  if (!base64Url) return ''
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return jsonPayload;
}

type LoginResponse = {
  token: string,
  _id: string,
}

export const login = async (context: any, user: any) => {
  console.log(`login: user before Axios: ${JSON.stringify(user, null, '\t')}`)
  
  const { data }: AxiosResponse<LoginResponse> = await Vue.axios.post('/api/login', user)
  
  const jsonPayload = decodeToken(data.token);
  const payload = JSON.parse(jsonPayload);
  const date = Math.floor(Date.now() / 1000);
  if (date >= payload.exp) {
    localStorage.removeItem('authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    //store.commit(SET_USER, null);
    store.commit(SET_USER_DATA, null);
    console.log(' >> token has been expired');
    router.push('/login');
  }
  else {
    console.log(`login: after axios response.data is: ${JSON.stringify(data, null, '\t')}`)
    localStorage.setItem('authorization', 'true');
    localStorage.setItem('uid', data._id);
    localStorage.setItem('token', `${data.token}`);
    //context.commit(SET_USER, response.data);
    context.commit(SET_USER_DATA, data);
    setAuthToken(`${data.token}`);
  }
};

export const logout = (context: any) => {
  Vue.axios.post(`/api/logout`, {
    user: store.state.auth.userData._id
  }).then(() => {
    localStorage.removeItem('authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('currentClient');
    //context.commit(SET_USER, null);
    context.commit(SET_USER_DATA, null);
    context.commit(SET_CURRENT_CLIENT, null);
    router.push('/login');
  }).catch((err: { message: any; }) => {
    console.log(err.message);    
  })  
};

export const loginWithOutlook = (context: any, params: any) => {
  Vue.axios.post(`api/outlook/login`, {
    code: params.code
  })
    .then((response:any) => {
      const name = response.data.name.split(' ');
      const userForLogin = {
        outlookId: response.data.uid,
        aud: response.data.aud,
        outlookToken: response.data.access_token,
        firstName: name[0],
        lastName: name[1],
        email: response.data.email,
      };
      console.log(response);
      context.dispatch(LOGIN, userForLogin).finally(() => {
        router.push('/company');
      });
    }).catch((e:any) => {
      console.log(e);
    });
};

export const loginWithGoogle = (context: any, $gAuth: any) => {

  $gAuth.signIn()
    .then((GoogleUser: any) => {
      const userInfo = GoogleUser.getBasicProfile();
      const userForLogin = {
        googleId: GoogleUser.getId(),
        googleToken: GoogleUser.getAuthResponse().access_token,
        lastName: userInfo.getFamilyName(),
        firstName: userInfo.getGivenName(),
        email: userInfo.getEmail(),
        googleImage: userInfo.getImageUrl()
      };
      console.log('auth.service.ts:loginWithGoogle, userForLogin =', JSON.stringify(userForLogin, null, '\t'))
      context.dispatch(LOGIN, userForLogin).finally(() => {
        router.push('/company');
        // this.$router.push({ name: 'about' });
      });
    });
};

export const getInfoAboutMe = async (context: any) => {
  const { data } = await Vue.axios.get('/api/user/' + localStorage.getItem('uid'))
  if (data) {
    context.commit(SET_USER_DATA, data.result);
    // context.commit(SET_CURRENT_USER, data.result);
    return data.result
  } else {
    console.log(`getInfoAboutUser: response data from '/api/user/me' is empty`);
  }
};

// процедура проверки актуальности токена, вызывается при каждом переходе по ссылке, из роутера
export const getInfoUser = async (token: any) => {
  function clear() {
    localStorage.removeItem('authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    //store.commit(SET_USER, null);
    store.commit(SET_USER_DATA, null);
    router.push('/login');
    return false
  }
  if (!token || token == 'undefined') return clear()

  const jsonPayload = decodeToken(token);
  const { exp, result: payload } = JSON.parse(jsonPayload);
  const date = Math.floor(Date.now() / 1000);
  
  
  if (date >= exp) return clear() 
  
  const user = store.state?.auth?.userData
  const client = store.state?.client?.currentClient
  const isSA = store.getters.userIsSuperAdmin

  if (!client) {
    const currentClient = JSON.parse(localStorage.getItem('currentClient') || 'null')
    if (currentClient) {
      if (isSA) store.commit(SET_CURRENT_CLIENT, currentClient)
      else store.dispatch(ENTER_CLIENT, currentClient.workspace)
    }
  }
  
  if (user != null) return false
  
  try {
    const info = await Vue.axios.get('/api/user/' + payload._id)
    if (!info) return null
    const { data } = info
    const userData = data.result
    store.commit(SET_USER_DATA, userData)
    console.log('userData was set');
    return userData
  } catch (error) {
    console.log('Error of connection!');
  }
  // const isSA = user.roles.includes('superadmin') 
  // if (!isSA) {
  //   const client = await store.dispatch(ENTER_CLIENT, userData.client_id)
  //   console.log('Entered:', client);
  // }
};
