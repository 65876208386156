import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import {
  ADD_EVENT,
  ADD_EVENTS,
  SET_CURRENT_USER,
  SET_EVENTS,
  CHANGE_EVENT,
  CHANGE_EVENTS,
  REMOVE_EVENT,
  REMOVE_EVENTS,
  SET_USERS,
  UPDATE_USER,
  IS_LOGGED_IN,
  ERROR_REGISTER,
  ERROR_LOGIN,
  SUCCESS_REGISTER,
  SET_USER_DATA,
  ADD_ROLE, REMOVE_ROLE, ADD_ROLE_TO_ALL, REMOVE_ROLE_FROM_ALL,
  REMOVE_USER_FILE,
  EXPORT_PROCESSING,
  SET_PINNED_USERS,
  SET_VACATIONS,
  SET_USER_VACATIONS,
  INSERT_USERS,
} from '@/store/user/mutations.type';

import { setAuthToken } from '@/services/auth/setAuthToken';
import { UploadResponse, FileUploadedInfo, FeedRequest } from '../dataTypes'

import store from "@/store";
import { SET_STRUCTURE } from '../structure/mutations.type';

const config = {
  onUploadProgress: function(progressEvent:any) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    store.state.common.progress = percentCompleted
  }
}

// функция преобразующая полученные данные по загруженному файлу в нужную структуру
const f = (fd:any) => ({
  filename: fd.file.name,
  filetype: fd.file.type,
  path: fd.url 
})

export function headers(data) {
  return { 
    headers: {
      'X-COMPANY-ID': data.client_id,
    }
  }
}

// проверка введенных данных (имя, пароль) при логине
export const checkUserInfo = async (context: any, dataUser: any) => {
  try {
    const { data, headers } = await Vue.axios.post(`api/loginPage`, dataUser)
    context.rootState.client.vendor = headers['x-vendor'] // где развернут проект
    // if (response == undefined) {
    //   context.commit(ERROR_LOGIN, { status: 522, server: `Ошибка сервера: Нет ответа на запрос` });
    // } 
    context.commit(ERROR_LOGIN, '');
    context.commit(IS_LOGGED_IN, !!data.token);
    context.commit(SET_USER_DATA, data.result);
    //localStorage.setItem('CurrentUserData', response.data.result.firstName);
    localStorage.setItem('authorization', 'true');
    localStorage.setItem('token', data.token);
    localStorage.setItem('uid', data.result._id);
    setAuthToken(data.token);
  } catch (e) {
    const error = e as AxiosError
    context.commit(ERROR_LOGIN, error.response?.data);
    localStorage.removeItem('authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('CurrentUserData');
  }
};


export const getUsers = async (context: any, client_workspace: string) => {
  const path = client_workspace ? `api/user/for_client/${client_workspace}` : 'api/user'
  try {
    const {data}: AxiosResponse = await Vue.axios.get(path)
    context.commit(SET_USERS, data.result);
  } catch (error) {
    console.log('Ошибка получения юзеров');
    context.commit(SET_USERS, []);
  }
};

// тип для передачи в фильтрацию на сервер
type userFilterData = {
  workspace: string,
  field: string,
  value: string
}
// получение юзеров по фильтру
export const getUsersFiltered = async (context: any, filterData: userFilterData) => {
  const {data}: AxiosResponse = await Vue.axios
    .post(`api/user/filter/${filterData.workspace}`, {data: filterData})
  context.commit(SET_USERS, data.result);
};

export const getUser = async (context: any, userId: string) => {
  try {
    const { data } = await Vue.axios.get(`api/user/${userId}`)
    context.commit(SET_CURRENT_USER, data.result);
    return data.result
  } catch (error) {
    const err = error as AxiosError
    console.log(`getUser Error: ${err.message}`);
  }  
};

export const editUser = async (context: any, user: any) => {
  // загрузка аватарки
  if (user?.avatar instanceof FormData) {
    const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', user.avatar)
    // data: {total_size: Number, results: Array of {file, filename, ...}}
    const mini = data.results[0].file.src
    user.pics = [{src: '', mini}]
    // оригинал авы
    if (user?.avatar_big instanceof FormData) {
      const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', user.avatar_big)
      // data: {total_size: Number, results: Array of {file, filename, ...}}
      user.pics[0].src = data.results[0].file.src
    }
  }
  if (user.pics?.length) console.table(`Avatars:`, user.pics[0])
  
  
  // возможно так везде надо сделать - если хранится строку с адресом, то ничего не делаем, а если поместили данные, то обновляем
  // либо надо изменить имя поля, как у аватарки - данные в поле "avatar", а путь в googleImage
  if (user?.cover instanceof FormData) { 
    const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', user.cover)
    // data: {total_size: Number, results: Array of {file, filename, ...}}
    user.cover = data.results[0].file.src
  }
  // загрузка файликов видимых эйчару
  if (user.formData) {
    // показываем прогресс
    store.state.common.progress = 0
    store.state.common.progressShow = true
    const files : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', user.formData, config)
    const newFiles = files.data.results.map((fd:any) => f(fd))
    user.files = [...user.files || [], ...newFiles]
    delete user.formData
    // ждем полсекунды и закрываем окно прогресса
    setTimeout(() => { store.state.common.progressShow = false},1500)
  }
  // загрузка самого сотрудника
  if (user.workingSince) {
    // This is needed since if my TZ is not UTC (e.g. Israel), the date before conversion is my local and axios attempts to convert the date to UTC - resulting in axios sending my date - 1 day
    console.log(user.workingSince)
    const date = new Date(user.workingSince)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    user.workingSince = `${year}-${month}-${day}`
    console.log(user.workingSince)
  }

  const response:AxiosResponse = await Vue.axios.put(`api/user/${user._id}`, user)
  const { result } = response.data
  
  context.commit(UPDATE_USER, result);
  
  // если редактируемый пользователь это залогиненный, изменяем данные залогиненного
  if (context.state.userData._id == result._id) {
    const userData = { ...context.state.userData, result }
    context.commit(SET_USER_DATA, userData);
  }
  context.commit(SET_CURRENT_USER, result);
};

export const insertNewUser = async (context: any, dataUser: any) => {
  // загрузка аватарки
  if (dataUser.avatar) {
    const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', dataUser.avatar)
    // data: {total_size: Number, results: Array of {file, filename, ...}}
    const res = data.results[0]
    dataUser.pics = [{src: '', mini: res.file.src }]
    if (dataUser?.avatar_big instanceof FormData) {
      const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', dataUser.avatar_big)
      const res = data.results[0]
      // data: {total_size: Number, results: Array of {file, filename, ...}}
      dataUser.pics[0].src = res.file.src
    }
  }
  // загрузка файликов видимых эйчару
  if (dataUser.formData) {
    // показываем прогресс
    store.state.common.progress = 0
    store.state.common.progressShow = true
    const files : AxiosResponse = await Vue.axios.post('api/upload', dataUser.formData, config)
    const newFiles = Array.isArray(files.data) ? files.data.map((fd:any) => f(fd)) : [f(files.data)]
    dataUser.files = [...dataUser.files || [], ...newFiles]
    delete dataUser.formData
    // ждем полсекунды и закрываем окно прогресса
    setTimeout(() => { store.state.common.progressShow = false},1500)
  }
  try {
    const {data}: AxiosResponse = await Vue.axios.post(`api/register`, dataUser)
    if (data.status === 200) {
      context.commit(ERROR_REGISTER, '');
      context.commit(SUCCESS_REGISTER, 'Регистрация прошла успешно');
      return data
    } else {
      context.commit(ERROR_REGISTER, data.error);
    }
  } catch (err) {
    //console.log(err);
    const error = err as AxiosError
    if (error.response?.status == 404) {
      context.commit(ERROR_REGISTER, (error.response.data as any).error);
    }
    
    localStorage.removeItem('authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('CurrentUserData');
  }
};

type UserShort = {
  email: string,
  firstName: string,
  lastName: string,
  middleName?: string,
  position: string,
}

// Добавление списка сотрудников
export const insertUserList = async (context: any, data: { client_id: string, users: UserShort[]}) => {
  const { data: respData } : AxiosResponse<any[]> = await Vue.axios.post('api/register_many', data)
  context.commit(INSERT_USERS, respData)
  return respData
}

export const markUserAsDeleted = (context: any, userId: string) => {
  return Vue.axios
    .put(`api/user/delete/${userId}`)
    .then((res: any) => {
      context.commit(UPDATE_USER, res.data)
    })
}

export const unmarkUserAsDeleted = (context: any, userId: string) => {
  return Vue.axios
    .put(`api/user/undelete/${userId}`)
    .then((res: any) => {
      context.commit(UPDATE_USER, res.data)
    })
}

/**
 * EVENTS
 */

export const getEvents = async (context: any, params: {clientId: string, userId: string}) => {
  const { data } = await Vue.axios
    //.get(`api/event/`, { params: { filter: { userId } } })
    // .get(`api/event/byUser/` + uid)
    .get(`api/user/${params.clientId}/events/access_for/${params.userId}`)
  context.commit(SET_EVENTS, data.result);
};

export const getEventsByClient = (context: any, workspace: string) => {
  return Vue.axios
    .get('/api/event/byClient/' + workspace)
    .then(response => {
      context.commit(SET_EVENTS, response.data);
    })
}

export const createEvent = async (context: any, event: any) => {
  // console.log(`createEvent starts on front: ${JSON.stringify(event, null, 3)}`);
  const {data} = await Vue.axios.post(`api/event/`, event)
  context.commit(ADD_EVENT, data.result)
  // console.log(`Событие добавлено: ${JSON.stringify(response.data.result, null, 3)}`);
  return data.result
};

export const createPeriodicEvent = async (context: any, event: any) => {
  const { data } = await Vue.axios.post(`api/event/periodic_event`, event) // передается одно событие 
  context.commit(ADD_EVENTS, data.result); // в результате приходит много событий 
  console.log(`Событие добавлено: `, data.result);
};

export const updateEvent = ({ commit }: any, event: any) => {
  //console.log(`service: updateEvent starts: ${JSON.stringify(event, null, 3)}`);
  return Vue.axios
    .put(`api/event/${event._id}`, event)
    .then((response: any) => {
      commit(CHANGE_EVENT, response.data.result);
      //console.log(`service: updateEvent end: ${JSON.stringify(response.data.result, null, 3)}`);
    });
};

export const updatePeriodicEvent = ({ commit }: any, event: any) => {
  return Vue.axios
    .put(`api/event/periodic_event/${event._id}`, event)
    .then((response: any) => {
      commit(CHANGE_EVENTS, response.data.result);
      //console.log(`service: updateEvent end: ${JSON.stringify(response.data.result, null, 3)}`);
    });
};

export const deleteEvent = ({ commit }: any, deleteId: any) => {
  return Vue.axios
    .delete(`api/event/${deleteId}`)
    .then((response: any) => {
      commit(REMOVE_EVENT, deleteId);
    });
};

export const deletePeriodicEvent = ({ commit }: any, param: { _id: any, all: boolean}) => {
  return Vue.axios
    .delete(`api/event/periodic_event/${param._id}?all=${param.all}`)
    .then((response: any) => {
      commit(REMOVE_EVENTS, response.data.result);
    });
}
/**
 * ROLES: add & delete
 */
export const insertRole = ({ commit }: any, data: { user_id: string, role: string, client_id: string | undefined }) => {
  if (data.user_id == 'all') {
    return Vue.axios
      .put(`api/clients/${data.client_id}/role/${data.role}`)
      .then((response: any) => {
        commit(ADD_ROLE_TO_ALL, data.role); // no using response
      });
  }
  return Vue.axios
    .put(`api/user/${data.user_id}/role/${data.role}`)
    .then((response: any) => {
      commit(ADD_ROLE, data); // no using response
    });
}

export const deleteRole = ({ commit }: any, data: { user_id: string, role: string, client_id: string | undefined }) => {
  if (data.user_id == 'all') {
    return Vue.axios
      .delete(`api/clients/${data.client_id}/role/${data.role}`)
      .then((response: any) => {
        commit(REMOVE_ROLE_FROM_ALL, data.role); // no using response
      });
  }
  return Vue.axios
    .delete(`api/user/${data.user_id}/role/${data.role}`)
    .then((response: any) => {
      commit(REMOVE_ROLE, data); // no using response
    });
}

// Экспорт всех сотрудников воркспейса в Sendsay
export const exportUserToSendsay = async ({ commit }: any, uid: string) => {
  commit(EXPORT_PROCESSING, true)
  const response = await Vue.axios.post(`api/user/export/${uid}`)
  commit(EXPORT_PROCESSING, false);
  return response.data
}

// Экспорт всех сотрудников воркспейса в Sendsay
export const exportUsersToSendsay = async ({ commit }: any, workspace: string) => {
  commit(EXPORT_PROCESSING, true)
  const response = await Vue.axios.post(`api/user/export/all/${workspace}`)
  commit(EXPORT_PROCESSING, false);
  return response.data
}

/**
 * Отправка писем с обратной связью: тип входной структуры и сама функция
 */
type TFeedbackData = {
  from: string,
  emails: string[],
  client_id: string,
  title: string,
  fields: {
    label: string,
    value: string | boolean
  },
  attachments: any,
  formData: any
}

export const serviceSendRequest = async ({ commit }: any, data: TFeedbackData) => {
  console.log(`Sending feedback to server...`);
  try {
    if (data.formData) {
      const response = await Vue.axios.post('api/upload', data.formData)
      data.attachments = response.data.results.map((d:any) => d.url) // url = direct link
      delete data.formData;
    } 
    return await Vue.axios.post(`api/user/send_service_req`, data)
  } catch (error) {
    return {
      result: (error as AxiosError).message,
      status: 500,
    }
  }
}

export const sendIdea = async (context: any, data: any) => {
  if (data.formData) {
    try {
      const uploadResponse = await Vue.axios.post('api/upload', data.formData)
      data.attachments = uploadResponse.data.results.map((d: any) => ({
        url: d.url,
        fileName: d.fileName
      }));
      delete data.formData
    } catch (uploadError) {
      console.error('Error uploading files:', uploadError)
    }
  }
  try {
    const response = await Vue.axios.post('api/user/send_idea', data)
    return response
  } catch (sendIdeaError) {
    console.error('Error sending idea:', sendIdeaError)
  }
};

export const sendViolationReport = (context: any, data: any) => {
  return Vue.axios
    .post('api/user/send_viol_report', data)
}

export const deleteHRFile = async (context: any, data: {user_id: string, file_id: string}) => {
  await Vue.axios.delete(`api/user/${data.user_id}/file/${data.file_id}`)
  return context.commit(REMOVE_USER_FILE, data)
}

// закрепление или открепление юзера
export const pinUser = async (context: any, user_id: string) => {
  const {data} = await Vue.axios.put(`api/user/pin/${user_id}`)
  // полностью обновляем юзеров, т.к. могут сильно поменяться местами строки
  return context.commit(SET_USERS, data) 
}

type PinMoveParams = {
  uid: string,
  direction?: number, // +1, 0, -1
  to?: number,  // перепрыгивание
}
type PinMovedResult = {
  uid: string,
  from: number,
  to: number
}
export const pinedMoveUser = async (context: any, params: PinMoveParams) => {
  const {data}: AxiosResponse<PinMovedResult> = await Vue.axios.put(`api/user/pin_move`, params)
  // полностью обновляем юзеров, т.к. могут сильно поменяться местами строки
  return context.commit(SET_PINNED_USERS, data) 
}

// Загрузка из 1С ЗУП на стороне бэка
type LoadHRMResult = {
  result: {
    ok: string[],
    created: [],
    no_emails: number,
  },
  message: string,
}
export const loadFromHRM = async (context: any, d: {client_id: string, replace: boolean}) => {
  try {
    const {data}: AxiosResponse<LoadHRMResult> = await Vue.axios.post('api/user/load_hrm', d)
    return data
  } catch (error) {
    return {error: 'loading_hrm_error', message: (error as AxiosError).response?.data}
  }
}

export const loadStructFromHRM = async (context: any, client_id: string) => {
  try {
    const {data}: AxiosResponse<LoadHRMResult> = await Vue.axios.post(`api/user/load_struct`, {client_id})
    context.commit(SET_STRUCTURE, data.result) 
    return data
  } catch (error) {
    return {error: 'loading_struct_hrm_error', message: (error as AxiosError).message}
  }
}

// сброс данных сотрудника к загруженным из ЗУП
export const resetToHRM = async (context: any, user_id: string) => {
  try {
    const {data}: AxiosResponse<LoadHRMResult> = await Vue.axios.post(`api/user/reset_to_hrm`, {user_id})
    if (data.result) {
      context.commit(UPDATE_USER, data.result)
      context.commit(SET_CURRENT_USER, data.result);
    }
    return data.result
  } catch (error) {
    return {error: 'reseting_hrm_error', message: (error as AxiosError).message}
  }
}

// Получение графика отпусков для всей организации
export const getVacations = async (context: any, getVacData: {client_id: string, dept?: string, search?: string}) => {
  try {
    const {data}: AxiosResponse = await Vue.axios.get(`api/user/vacations?dept=${getVacData.dept || ''}&search=${getVacData.search || ''}`, headers(getVacData))
    if (data.result) {
      context.commit(SET_VACATIONS, data.result)
    }
    return data.result
  } catch (error) {
    return {error: 'reseting_hrm_error', message: (error as AxiosError).message}
  }
}

type VacationData = {
  _id?: string, // код конкретного отпуска
  client_id: string,
  user: string,
  start: Date,
  end: Date,
  status: string,
}
export const saveVacation = async (context: any, vacData: VacationData) => {
  try {
    const {data}: AxiosResponse = vacData._id ? 
      await Vue.axios.put(`api/user/vacation/${vacData._id}`, vacData) :
      await Vue.axios.post(`api/user/vacation`, vacData)
    if (data.result) {
      const { user, planned } = data.result // тут целиком для юзера все отпуски
      context.commit(SET_USER_VACATIONS, {user, planned})
      return {user, planned}
    }
    return data
  } catch (error) {
    const err = error as AxiosError<{message: string}>
    return {error: 'edit_vac_error', message: err.response?.data?.message || err.message}
  }
}

type VacationDelData = {
  _id?: string, // код конкретного отпуска
  client_id: string,
  user: string,
}
export const delVacation = async (context: any, vacData: VacationDelData) => {
  try {
    const {data}: AxiosResponse = await Vue.axios.delete(`api/user/vacation/${vacData.user}/${vacData._id}`)
    const { user, planned } = data.result
    context.commit(SET_USER_VACATIONS, {user, planned})
    return {user, planned}
  } catch (err) {
    return {error: 'del_vac_error', message: (err as AxiosError).message}
  }
}