// получение одного клиента
export const GET_CLIENT = 'GET_CLIENT'
// Получение списка клиентов
export const GET_CLIENTS = 'GET_CLIENTS'
// создание клиента
export const CREATE_CLIENT = 'CREATE_CLIENT'
// обновление данных клиента
export const EDIT_CLIENT = 'EDIT_CLIENT'
export const CLEAR_CLIENT = 'CLEAR_CLIENT'

// enter to client workspace
export const ENTER_CLIENT = 'ENTER_CLIENT'
export const EXIT_CLIENT = 'EXIT_CLIENT'

// data statistic by client
export const GET_STATISTIC = 'GET_STATISTIC'
export const GET_FILES_STAT = 'GET_FILES_STAT'
export const DROP_COLLECTION = 'DROP_COLLECTION'

// список всех фич клиента
export const GET_FEATURES = 'GET_FEATURES'
// переключение фичи у клиента - вкл/выкл
export const SWITCH_FEATURE = 'SWITCH_FEATURE'

export const CLONE_CLIENT = 'CLONE_CLIENT'
export const ERASE_CLIENT = 'ERASE_CLIENT'

export const SEND_TO_TELEGRAM = 'SEND_TO_TELEGRAM'
// получить "зависшие" файлы, которые нигде не используются (для ручного удаления или автоматически)
export const GET_HANGED_FILES = 'GET_HANGED_FILES'
// удаление неиспользуемых файлов
export const DEL_UNUSED_FILES = 'DEL_UNUSED_FILES'
// удаление произвольного файла
export const DEL_FILE = 'DEL_FILE'
// получение журнала логов по клиенту
export const GET_JOURNAL = 'GET_JOURNAL'

export const GET_THANKS = 'GET_THANKS'
// Выгрузка данных в SendSay
export const EXPORT = 'EXPORT'
export const EXPORT_ALL = 'EXPORT_ALL'

export const GET_BDAYS = 'GET_BDAYS'

export const GET_NOTIF_TEMPLATES = 'GET_NOTIF_TEMPLATES'
export const SAVE_NOTIF_TEMPLATE = 'SAVE_NOTIF_TEMPLATE'
export const DEL_NOTIF_TEMPLATE = 'DEL_NOTIF_TEMPLATE'

export const RUN_MAINTENANCE_TASK = 'RUN_MAINTENANCE_TASK'
