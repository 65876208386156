import {
  GET_COMPANY_FEED, GET_USER_FEED,
  GET_POSTS, GET_POSTS_OF_FLWS, GET_POSTS_OF_BLOG, GET_POSTS_OF_GROUP, GET_POSTS_ALL,
  LOAD_POST, GET_POST, UPDATE_POST, EDIT_POST, DELETE_POST, REPOST,
  SEND_COMMENT, EDIT_COMMENT, DELETE_COMMENT,
  SEND_LIKE,
  SEND_NEW_POST,
  GET_POSTS_OF_GROUPS,
  GET_COMMENTS,
  GET_MENTIONS,
  VIEW_POST,
} from './actions.type';

import { } from './actions.type';
import {
  deletePost, editPost, getPosts, getPost, loadPost, repost,
  getCompanyFeed, getUserFeed, getPostsOfFollows, getPostsOfuserGroups, getPostsOfBlog, getPostsOfGroup, getAllPosts,
  sendComment, sendLike, sendNewPost, updatePost, editComment, deleteComment, getComments, getMentions, viewPost
} from './post.service';

import {
  ADD_ANSWER_FOR_COMMENT,
  ADD_COMMENT_FOR_POST,
  ADD_LIKE_FOR_COMMENT,
  ADD_LIKE_FOR_POST,
  ADD_LIKE_FOR_ANSWER,
  ADD_POST, CHANGE_POST, CHANGE_COMMENT, CHANGE_ANSWER,
  REMOVE_POST, SET_EDIT_POST_VISIBLE, SET_POST_FOR_EDITING, SET_EDIT_COMMENT_VISIBLE, SET_COMMENT_FOR_EDITING,
  SET_POSTS, SET_POSTS_OF_FOLLOWS, SET_POSTS_OF_GROUPS, SET_POST,
  REMOVE_COMMENT, REMOVE_ANSWER,
  SET_COMMENTS_FEED, SET_MENTIONS_FEED,
  SET_POST_VIEWED,
  SET_COMPANY_FEED, SET_USER_FEED,
  TOGGLE_EMOJI_PICKER, SET_EMOJI,
  SET_POST_TEXT,
} from './mutations.type';

import * as Methods from './mutations/methods'

export interface State {
  company_feed: any[],
  user_feed: any[],
  notif_feed: any[],
  feeds_metadata: {
    company: {
      pages: number,
      total: number
    },
    user: {
      pages: number,
      total: number
    }
  },
  posts: any[];
  posts_of_follows: any[],
  posts_of_groups: any[],
  currentPost: any,
  comments: any[];
  comments_feed: any[],
  mentions_feed: any[],
  postForEditing: any;
  commentForEditing: any;
  editPostVisible: boolean;
  editCommVisible: boolean;
  emoji_target: string,
  showEmojiPicker: boolean;
  emoji: string,
  postText: string,
}

const store: State = {
  company_feed: [],
  user_feed: [],
  notif_feed: [],
  feeds_metadata: {
    company: {
      pages: 0,
      total: 0
    },
    user: {
      pages: 0,
      total: 0
    }
  },
  postText: '',
  posts: [],
  posts_of_follows: [],
  posts_of_groups: [],
  currentPost: null,
  comments: [],
  comments_feed: [],
  mentions_feed: [],
  postForEditing: null,
  commentForEditing: null,
  editPostVisible: false,
  editCommVisible: false,
  emoji_target: '',
  showEmojiPicker: false,
  emoji: '',
};

function compare(a: any, b: any) {
  if (a.created < b.created) return 1;
  if (a.created > b.created) return -1;
  return 0;
}

const getters = {
  posts(state: State) {
    return state.posts;
  },
  currentPost(state: State) {
    return state.currentPost;
  },
  comments(state: State) {
    return state.comments;
  },
  comments_feed(state: State) {
    return state.comments_feed;
  },
  commentForEditing(state: State) {
    return state.commentForEditing;
  },
  editCommVisible(state: State) {
    return state.editCommVisible;
  },
  posts_company(state: State) {
    if (!state.posts) return []
    return state.posts.filter(p => p.parent.type == 'company')
  },
  posts_of_follows(state: State) {
    return state.posts_of_follows
  },
  posts_of_groups(state: State) {
    return state.posts.filter(p => p.parent.type == 'group') //posts_of_groups
  },
  posts_of_feed(state: State) {
    if (!state.posts) return []
    return state.posts.filter(p => p.parent.type == 'feed')
  },
  posts_of_system(state: State) {
    return state.posts.filter(p => p.author == 'system')
  },
  mentions_feed(state: State) {
    return state.mentions_feed
  },
  /**
   * Объединение всех типов постов в одну коллекцию
   */
  allPosts(state: State, getters: any): any[] {
    // для групп, определение что пост из доступной для пользователя группы
    const isAvailable = (id: string) => getters.groups_available.some((g: any) => g._id == id)
    return state.posts.filter(post => {
      // для групп проверяем доступность, для остальных - отсекаем новости компании
      return post.parent.type == 'group' ? isAvailable(post.parent.id) : post.parent.type != 'company'
    }).sort(compare)
  }
}

const actions = {
  [GET_COMPANY_FEED]: getCompanyFeed,
  [GET_USER_FEED]: getUserFeed,
  [SEND_NEW_POST]: sendNewPost,
  [GET_POSTS]: getPosts,
  [LOAD_POST]: loadPost,
  [GET_POSTS_ALL]: getAllPosts,
  [GET_POSTS_OF_BLOG]: getPostsOfBlog,
  [GET_POSTS_OF_FLWS]: getPostsOfFollows,
  [GET_POSTS_OF_GROUP]: getPostsOfGroup,
  [GET_POSTS_OF_GROUPS]: getPostsOfuserGroups,
  [GET_POST]: getPost,
  [UPDATE_POST]: updatePost,
  [SEND_LIKE]: sendLike,
  [SEND_COMMENT]: sendComment,
  [DELETE_POST]: deletePost,
  [DELETE_COMMENT]: deleteComment,
  [REPOST]: repost,
  [EDIT_POST]: editPost,
  [EDIT_COMMENT]: editComment,
  [GET_COMMENTS]: getComments,
  [GET_MENTIONS]: getMentions,
  [VIEW_POST]: viewPost,
};

// const replaceVal = (arr: any, val: any, newVal: any) => {
//   const n = arr.findIndex((a: any) => a == val);
//   return n == -1 ? arr : [...arr.slice(0, n), newVal, ...arr.slice(n + 1)]
// }

type Like = {
  id: string,
  like_type: 'post' | 'comment' | 'answer', // what user liked
  where: 'company' | 'blog' | 'feed', // blog, company, ...
  post_id: string, // тут всегда код поста, даже если лайк комменту или ответу, чтобы знать где искать
  user_id: string,
  emoji: string,
  likes: any[],
}

type WallPageResp = {
  result: any[],
  meta: {
    page: number,
    pages: number,
    total: number
  }
}

const mutations = {
  [SET_POSTS](state: State, posts: any[]) {
    state.posts = posts;
    state.currentPost = null
  },

  [SET_POSTS_OF_FOLLOWS](state: State, posts: any[]) {
    state.posts_of_follows = posts;
  },
  [SET_POSTS_OF_GROUPS](state: State, posts: any[]) {
    state.posts = [...state.posts, posts];
    state.currentPost = null
  },

  [SET_POST](state: State, post: any[]) {
    state.currentPost = post;
  },

  [ADD_POST]:         Methods.addPost,
  [REMOVE_POST]:      Methods.removePost,
  [SET_POST_VIEWED]:  Methods.setPostViewed,
  
  [ADD_COMMENT_FOR_POST]:   Methods.addCommentToPost,
  [REMOVE_COMMENT]:         Methods.removeComment,
  [ADD_ANSWER_FOR_COMMENT]: Methods.addAnswerToComment,
  [REMOVE_ANSWER]:          Methods.removeAnswer,

  [ADD_LIKE_FOR_POST]:      Methods.addLikeToPost,
  [ADD_LIKE_FOR_COMMENT]:   Methods.addLikeToComment,
  [ADD_LIKE_FOR_ANSWER]:    Methods.addLikeToAnswer,

  [CHANGE_POST]:    Methods.changePost,
  [CHANGE_COMMENT]: Methods.changeComment,
  [CHANGE_ANSWER]:  Methods.changeAnswer,

  [SET_POST_FOR_EDITING](state: State, post: any) {
    state.postForEditing = post;
  },

  [SET_COMMENT_FOR_EDITING](state: State, comment: any) {
    state.commentForEditing = comment;
  },

  [SET_EDIT_POST_VISIBLE](state: State, visible: any) {
    state.editPostVisible = visible;
  },

  [SET_EDIT_COMMENT_VISIBLE](state: State, visible: any) {
    state.editCommVisible = visible;
  },
  [SET_COMMENTS_FEED](state: State, data: any) {
    state.comments_feed = data
  },
  [SET_MENTIONS_FEED](state: State, data: any) {
    state.mentions_feed = data
  },
  
  [SET_COMPANY_FEED](state: State, data: WallPageResp) {
    state.company_feed.splice(data.meta.page * 20, 20, ...data.result)
    state.feeds_metadata.company = data.meta
    state.currentPost = null // сброс чтоб не глючило после возврата в ленту со страницы поста
  },
  [SET_USER_FEED](state: State, data: WallPageResp) {
    state.user_feed.splice(data.meta.page * 20, 20, ...data.result)
    state.feeds_metadata.user = data.meta
    state.currentPost = null
  },
  [TOGGLE_EMOJI_PICKER](state: State, data: string) {
    state.emoji_target = state.showEmojiPicker ? '' : data // чтобы различать к какому полю прикреплять эмоджи
    state.showEmojiPicker = !state.showEmojiPicker  // флаг показа окна
    state.emoji = ''  // само выбранное эмодзи
  },
  [SET_EMOJI](state: State, data: string) {
    state.emoji = data
  },
  [SET_POST_TEXT](state: State, text: string) {
    state.postText = text
  },
};

export default {
  state: store,
  getters,
  actions,
  mutations,
};
